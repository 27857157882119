import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, split } from 'apollo-link'
import { ApolloProvider } from 'react-apollo'
import { withClientState } from 'apollo-link-state'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { CachePersistor } from 'apollo-cache-persist'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from 'apollo-upload-client'

import NoConnection from 'components/no-connection'
import Chat from './pages/chat'
import Young from './pages/young'
import YoungTimeline from './pages/young/timeline'
import YoungProfile from './pages/young/profile'
import Settings from './pages/settings'
import Login from './pages/login'

import { theme } from 'design-system'

const isDev = process.env.NODE_ENV === 'development'

const httpLink = createUploadLink({
  uri: isDev
    ? 'http://localhost:4000'
    : 'https://muse-carmarthen-graphql.herokuapp.com',
})

const cache = new InMemoryCache()

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  debug: true,
})

const stateLink = withClientState({
  cache,
  resolvers: {},
  defaults: {
    authStatus: {
      __typename: 'authStatus',
      logged: false,
      user: null,
      role: null,
    },
  },
})

const authMiddleware = setContext(async (req, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.error('ApolloClient networkError: ', networkError)
  if (graphQLErrors) {
    // eslint-disable-next-line
    graphQLErrors.map(({ message, locations, path }) => {
      console.error('ApolloClient graphQLErrors: ', message)
      if (message.indexOf('Authorised') > -1) {
        // every 401/unauthorized error will be caught here and update the global local state
        localStorage.clear()

        cache.writeData({
          data: {
            authStatus: {
              __typename: 'authStatus',
              logged: false,
            },
          },
        })
      }
    })
  }
})

const wsLink = new WebSocketLink({
  uri: isDev
    ? 'ws://localhost:4000'
    : 'wss://muse-carmarthen-graphql.herokuapp.com',
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem('token'),
    },
  },
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  ApolloLink.from([authMiddleware, errorLink, stateLink, httpLink])
)

const client = new ApolloClient({
  link,
  cache,
  resolvers: {},
})

client.onResetStore(stateLink.writeDefaults)

class App extends Component {
  state = {
    restored: false,
    networkConnection: true,
  }

  handleConnection = e => {
    if (e.type === 'online') {
      this.setState({ networkConnection: true })
    }
    if (e.type === 'offline') {
      this.setState({ networkConnection: false })
    }
  }

  componentDidMount = async () => {
    window.addEventListener('offline', this.handleConnection)
    window.addEventListener('online', this.handleConnection)
    await persistor.restore()
    this.setState({ restored: true })
  }

  componentWillUnmount = () => {
    window.removeEventListener('offline', this.handleConnection)
    window.removeEventListener('online', this.handleConnection)
  }

  addDefaultSrc = ev => {
    ev.target.src = './images/pensive-face.png'
  }

  render() {
    const { networkConnection, restored } = this.state

    return restored ? (
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            {networkConnection ? (
              <Switch>
                <Route exact path="/" component={Young} />
                <Route exact path="/chat" component={Chat} />
                {/* <Route exact path="/young" component={Young} /> */}
                <Route exact path="/young/:id" component={YoungTimeline} />
                <Route exact path="/young/:id/info" component={YoungProfile} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/login" component={Login} />
              </Switch>
            ) : (
              <NoConnection
                onDismiss={() => this.setState({ networkConnection: true })}
              />
            )}
          </HashRouter>
        </ThemeProvider>
      </ApolloProvider>
    ) : (
      <div>Loading</div>
    )
  }
}

export default App
